import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Title from '../components/Title';

import ImageDigital from '../../assets/images/services/digital_small.jpg';
import ImageLetterpress from '../../assets/images/services/letterpress_small.jpg';
import ImageHotfoil from '../../assets/images/services/hotfoil_small.jpg';
import ImageThermal from '../../assets/images/services/thermal_small.jpg';

import BannerImage from '../../assets/images/banner-home.jpg';
import SamplePackImage from '../../assets/images/sample-pack.jpg';
import TradeOnlyImageWhite from '../../assets/images/trade-only-white.png';

import ImageColors from '../../assets/images/about-background-2.jpg';

const Service = props => (
  <div className="col-md-6 col-sm-6">
    <div className="c-content-media-1 c-bordered" style={{ marginBottom: '30px' }}>
      <h3 className="c-title c-font-uppercase c-font-bold c-font-center" style={{ margin: '0 0 20px 0' }}>
        {props.title}
      </h3>
      <div className="c-font-center">
        <img src={props.image} alt={props.title} width="80%" />
        <p className="c-margin-t-30" dangerouslySetInnerHTML={{ __html: props.description }} />
        <Link to={props.link} className="btn c-btn-uppercase btn-md c-btn-sbold c-btn-square c-theme-btn c-margin-t-10">
          Learn More
        </Link>
        <div className="c-margin-t-30">
          <strong>{props.company}</strong>
          <br />
          <i className="icon-call-end" /> {props.phone}
          <br />
          <i className="icon-envelope" />{' '}
          <a href={`mailto:${props.email}`} target="_blank" rel="noopener noreferrer">
            {props.email}
          </a>
        </div>
      </div>
    </div>
  </div>
);

const Hero = () => (
  <section>
    <div className="banner">
      <div className="banner-content">
        <h1>The Calf Hey Group</h1>
        <h2>Total Label Solutions</h2>
      </div>
      <div className="banner-image">
        <img src={BannerImage} alt="banner" width="100%" />
      </div>
    </div>
  </section>
);

const metaDescription = 'The Calf Hey Group - Total Label Solutions';
const metaKeywords = 'printing, print, label, labels, hotfoil, thermal, digital, letterpress';

const IndexPage = () => (
  <Layout title="Total Label Solutions" description={metaDescription} keywords={metaKeywords}>
    <Hero />

    <div className="c-content-box c-size-md">
      <div className="container">
        <div className="row">
          <Title
            main="Who we are"
            helper="<span class='c-font-28'>At Calf Hey we are dedicated to providing you, the customer, with a self adhesive labelling service that is second to none. From initial contact to the delivery of your labels, we do everything possible to make dealing with us a pleasant experience.</span>"
          />
          <div
            className="c-content-panel c-font-center"
            style={{
              backgroundImage: `url(${ImageColors})`,
              height: '140px'
            }}
          />

          <div className="c-font-center">
            <p className="c-font-28 c-font-white c-font-center c-margin-t-50 ">
              CALF HEY IS A <strong>TRADE ONLY</strong> MANUFACTURER OF HIGH QUALITY SELF ADHESIVE LABELS.
            </p>
            <img src={TradeOnlyImageWhite} alt="trade-only-company" height="120px" />
          </div>
        </div>
      </div>
    </div>

    <div className="c-content-box c-size-md">
      <div className="container">
        <Title
          main="Our Services"
          helper={`
        <p class="c-font-28"><strong>Calf Hey Design</strong> operates the Hotfoil and Thermal processes. <br /> <strong>Calf Hey Rotary</strong> operates the Letterpress and Digital processes.</p> <br />
        <p>Each of these facilities operates with their own dedicated team, specialising in their own particular range of production. <br /> However, they are sister companies located in the same location and work together on projects to ensure you receive the best price and product to match your specific needs.</p>
      `}
        />
        <div className="row display-flex">
          <Service
            title="Hotfoil"
            image={ImageHotfoil}
            description={`Printing with a wide range of coloured foils is our most commonly used process due to its high quality, cost effectiveness and ability to produce labels in any quantity.<br /><br />`}
            link="/services/hotfoil/"
            company="Calf Hey Design"
            email="sales@calf-hey.com"
            phone="01484 542795"
          />
          <Service
            title="Thermal"
            image={ImageThermal}
            description={`Thermal Printing requires no printing plates. Ideal for consecutive numbering and barcoding in any quantity.`}
            link="/services/thermal/"
            company="Calf Hey Design"
            email="sales@calf-hey.com"
            phone="01484 542795"
          />
          <Service
            title="Letterpress"
            image={ImageLetterpress}
            description={`Ink printing is best suited from medium to higher volume runs and also offers the ability to match to Pantone colours and print halftone screens in up to 6 colours, including full colour labels.<br /><br />`}
            link="/services/letterpress/"
            company="Calf Hey Rotary"
            email="Chris@calf-hey.co.uk"
            phone="01484 448033"
          />
          <Service
            title="Digital"
            image={ImageDigital}
            description={`High quality, long life full colour labels without the need for originations. Special and intricate shapes die cut without the need for purchasing cutters. Very cost effective.`}
            link="/services/digital/"
            company="Calf Hey Rotary"
            email="Chris@calf-hey.co.uk"
            phone="01484 448033"
          />
        </div>
      </div>
    </div>

    <div className="c-content-box c-size-md">
      <div className="container">
        <div className="c-content-feature-10">
          <Title
            main="What makes Calf Hey second to none?"
            helper="Design Services + Friendly Staff + Confidentially Assured = A Pleasant Experience"
          />
          <ul className="c-list display-flex">
            <li>
              <div className="c-card c-bg-white c-font-right">
                <i className="icon-badge c-font-white c-font-27 c-theme-bg c-float-right" />
                <div className="c-content c-content-right">
                  <h3 className="c-font-uppercase c-font-bold">STUDIO FACILITIES</h3>
                  <p>
                    From artwork tweaks through to full label design, we have invested in the most up-to-date software
                    and equipment to ensure the best service possible.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="c-card c-bg-white">
                <i className="icon-users c-font-white c-font-27 c-theme-bg c-float-left" />
                <div className="c-content c-content-left">
                  <h3 className="c-font-uppercase c-font-bold">OFFICE</h3>
                  <p>
                    Our fully experienced and friendly office team are on hand to offer you advice, help and technical
                    information. Every enquiry is looked at individually to ensure that we offer you the best price and
                    production method for every job.
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <ul className="c-list display-flex">
            <li>
              <div className="c-card c-bg-white c-font-right">
                <i className="icon-layers c-font-white c-font-27 c-theme-bg c-float-right" />
                <div className="c-content c-content-right">
                  <h3 className="c-font-uppercase c-font-bold">PRODUCTION</h3>
                  <p>
                    Having so many varied processes and machines at hand gives us the widest variety of solutions, to
                    ensure you, our customer, are provided with the correct product for your clients.
                    <br />
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="c-card c-bg-white">
                <i className="icon-rocket c-font-white c-font-27 c-theme-bg c-float-left" />
                <div className="c-content c-content-left">
                  <h3 className="c-font-uppercase c-font-bold">DESPATCH</h3>
                  <p>
                    Receiving your product on time is crucial to ensure that your customer is satisfied. At Calf Hey we
                    understand this and have built a business on service. That is why only thoroughly vetted national
                    parcel distributors are engaged to deliver your product. We can deliver direct to your customers if
                    required under plain cover.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    {/* Features */}
    <div className="c-content-box c-size-md">
      <div className="container">
        <Title main="Need more convincing?" helper="Let's do it old school - we will send you a FREE sample!" />
        <div className="row">
          <div className=" col-md-12">
            <div className="c-content-media-1 c-bordered  c-font-center" style={{ minHeight: '380px' }}>
              <div className="c-content-label c-font-uppercase c-font-bold c-theme-bg">FREE</div>
              <h3 className="c-title c-font-uppercase c-font-bold">Free Brochure & Free Sample PACK</h3>

              <p>
                Our current brochure is packed with information about us and our products. If you would like a brochure,
                please let us know and we will put one in the post straight away. The brochure is included as part of
                our continually updated sample pack.
              </p>

              <img
                className="c-margin-t-40 c-margin-b-40"
                src={SamplePackImage}
                alt="brochure-and-sample-pack"
                width="80%"
              />

              <p className="c-margin-t-30">
                The Sample pack contains a wide variety of labels that are manufactured by the Calf Hey Group. They
                include Plain, Printed and Specialist Labels such as 'Split-Lam' or domed labels.
              </p>

              <div className="c-margin-t-30">
                <Link to="/sample/" className="btn c-btn-uppercase btn-md c-btn-sbold c-btn-square c-theme-btn">
                  REQUEST NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
